
import { AnalyticsEvent } from "@evercam/shared/types"

export default {
  props: {
    projects: {
      type: Array,
      required: true,
    },
    cameras: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    days: {
      type: Array,
      required: true,
    },
    status: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      selectedProjects: [],
      selectedCameras: [],
      selectedUsers: [],
      selectedDays: [],
      selectedStatus: [],
      editValueDebounce: null,
      editValueDebounceTimeOut: 400,
    }
  },
  watch: {
    projects: {
      handler(newProjects, oldProjects) {
        const addedItems = newProjects.filter(
          (item) =>
            !oldProjects.some((old) => old.value === item.value) &&
            !this.selectedProjects.some((old) => old.value === item.value)
        )
        if (addedItems.length > 0) {
          this.selectedProjects = [...this.selectedProjects, ...addedItems]
        }

        this.selectedProjects = this.selectedProjects.filter((selected) =>
          newProjects.some((item) => item.value === selected.value)
        )
      },
      deep: true,
    },
    cameras: {
      handler(newCameras, oldCameras) {
        const addedItems = newCameras.filter(
          (item) =>
            !oldCameras.some((old) => old.value === item.value) &&
            !this.selectedCameras.some((old) => old.value === item.value)
        )
        if (addedItems.length > 0) {
          this.selectedCameras = [...this.selectedCameras, ...addedItems]
        }

        this.selectedCameras = this.selectedCameras.filter((selected) =>
          newCameras.some((item) => item.value === selected.value)
        )
      },
      deep: true,
    },
    users: {
      handler(newUsers, oldUsers) {
        const addedItems = newUsers.filter(
          (item) =>
            !oldUsers.some((old) => old.email === item.email) &&
            !this.selectedUsers.some((old) => old.email === item.email)
        )
        if (addedItems.length > 0) {
          this.selectedUsers = [...this.selectedUsers, ...addedItems]
        }

        this.selectedUsers = this.selectedUsers.filter((selected) =>
          newUsers.some((item) => item.email === selected.email)
        )
      },
      deep: true,
    },
    search(newValue) {
      if (!newValue) {
        return
      }

      clearTimeout(this.editValueDebounce)
      this.editValueDebounce = setTimeout(() => {
        this.$analytics.saveEvent(
          AnalyticsEvent.ProgressPhotoEditSearchFilter,
          {
            searchFilter: newValue,
          }
        )
      }, this.editValueDebounceTimeOut)
    },
  },
  mounted() {
    this.selectedProjects = this.projects
    this.selectedCameras = this.cameras
    this.selectedUsers = this.users
    this.selectedDays = this.days
    this.selectedStatus = this.status
  },
  methods: {
    toggleSelectAll(type) {
      let toggledAttribute = null
      switch (type) {
        case "cameras":
          this.selectedCameras =
            this.selectedCameras.length === this.cameras.length
              ? []
              : [...this.cameras]
          toggledAttribute = "selectedCameras"
          break
        case "projects":
          this.selectedProjects =
            this.selectedProjects.length === this.projects.length
              ? []
              : [...this.projects]
          toggledAttribute = "selectedProjects"
          break
        case "users":
          this.selectedUsers =
            this.selectedUsers.length === this.users.length
              ? []
              : [...this.users]
          toggledAttribute = "selectedUsers"
          break
        case "days":
          this.selectedDays =
            this.selectedDays.length === this.days.length ? [] : [...this.days]
          toggledAttribute = "selectedDays"
          break
        case "status":
          this.selectedStatus =
            this.selectedStatus.length === this.status.length
              ? []
              : [...this.status]
          toggledAttribute = "selectedStatus"
          break
      }
      this.onFiltersUpdate(this[toggledAttribute], type)
    },
    onFiltersUpdate(newValue, type) {
      // Save proper event name with newly selected values
      const filterEventsMappings = {
        cameras: {
          eventName: AnalyticsEvent.ProgressPhotoEditCamerasFilter,
          filterValue: newValue.map((e) => e.value).join(","),
        },
        projects: {
          eventName: AnalyticsEvent.ProgressPhotoEditProjectsFilter,
          filterValue: newValue.map((e) => e.value).join(","),
        },
        users: {
          eventName: AnalyticsEvent.ProgressPhotoEditUsersFilter,
          filterValue: newValue.map((e) => e.email).join(","),
        },
        days: {
          eventName: AnalyticsEvent.ProgressPhotoEditDaysFilter,
          filterValue: newValue.join(","),
        },
        status: {
          eventName: AnalyticsEvent.ProgressPhotoEditStatusFilter,
          filterValue: newValue.join(","),
        },
      }
      const eventMapping = filterEventsMappings[type]
      if (eventMapping) {
        this.$analytics.saveEvent(eventMapping.eventName, {
          filterValue: eventMapping.filterValue,
        })
      }

      // Emit the updated filters
      this.$emit("filters-update", {
        search: this.search,
        selectedProjects: this.selectedProjects,
        selectedCameras: this.selectedCameras,
        selectedUsers: this.selectedUsers.map((user) => user.email),
        selectedDays: this.selectedDays,
        selectedStatus: this.selectedStatus,
      })
    },
    usersFilter(item, queryText) {
      const textOne = item.name?.toLowerCase() || ""
      const textTwo = item.email.toLowerCase()
      const searchText = queryText.toLowerCase()

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      )
    },
  },
}
