
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "DefaultPageWrapper",
  components: { EvercamLoadingAnimation },
  props: {
    title: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showPlaceholder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFilters: false,
      contentHeight: 0,
    }
  },
  computed: {
    hasFiltersScopedSlot() {
      return this.$scopedSlots?.["filters"]
    },
    hasLoadingScopedSlot() {
      return this.$scopedSlots?.["loading"]
    },
  },
  watch: {
    showFilters(value) {
      this.$emit("show-filters", value)
    },
  },
  methods: {
    onResize() {
      const contentTop = this.$refs.content?.getBoundingClientRect()?.top || 0
      const footerHeight =
        this.$refs.footer?.getBoundingClientRect()?.height || 0
      this.contentHeight = window.innerHeight - contentTop - footerHeight
    },
  },
}
