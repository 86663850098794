
import RecipientsSelector from "@/components/RecipientsSelector"
import CamerasAutocomplete from "@/components/CamerasAutocomplete"
import weekDays from "@evercam/shared/constants/weekDays"
import timezones from "@evercam/shared/constants/timezones"
import { CameraStatus } from "@evercam/shared/types/camera"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import { mapStores } from "pinia"
import { useProgressPhotoStore } from "@/stores/progressPhotos"
import { useConnectorStore } from "@/stores/connector"
import { useAccountStore } from "@/stores/account"
import { AnalyticsEvent, AutomationProvider } from "@evercam/shared/types"
import TimePicker from "@evercam/shared/components/TimePicker"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import AconexForm from "@/components/connectors/AconexForm"
import { camelize } from "humps"

export default {
  components: {
    Autocomplete,
    CamerasAutocomplete,
    RecipientsSelector,
    TimePicker,
    AconexForm,
  },
  props: {
    progressPhoto: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentProgressPhoto: this.progressPhoto,
      selectedProviderItem: "",
      providerItems: [
        { name: "Email", value: AutomationProvider.Email },
        { name: "Procore", value: AutomationProvider.Procore },
        { name: "Aconex", value: AutomationProvider.Aconex },
        { name: "Autodesk", value: AutomationProvider.Autodesk },
      ],
      selectedAconexProject: null,
      aconexDocumentSchema: null,
      selectedAutodeskHub: null,
      selectedAutodeskProject: null,
      selectedAutodeskFolder: null,
      selectedProcoreProject: null,
      selectedProcoreProjectAlbum: null,
      timezones,
      days: weekDays,
      timePickerMenu: false,
      loading: false,
      allDayStatus: false,
      progressPhotoTime: new Date().getHours() + ":00",
      isGettingDocumentSchema: false,
      aconexForm: null,
    }
  },
  computed: {
    ...mapStores(useProgressPhotoStore, useConnectorStore, useAccountStore),
    allDaysSelected() {
      return this.currentProgressPhoto.notifyDays.length == 7
    },
    autodeskFolderName() {
      const selectedFolder = this.selectedAutodeskFolder?.[0]

      return selectedFolder
        ? (selectedFolder.parentName ?? "") + selectedFolder.text
        : null
    },
    isEmailProviderSelected() {
      return this.selectedProviderItem === AutomationProvider.Email
    },
    isProcoreActive() {
      return (
        this.selectedProviderItem === AutomationProvider.Procore &&
        this.connectorStore.isProcoreConnected
      )
    },
    isAconexActive() {
      return (
        this.selectedProviderItem === AutomationProvider.Aconex &&
        this.connectorStore.isAconexConnected
      )
    },
    isAutodeskActive() {
      return (
        this.selectedProviderItem === AutomationProvider.Autodesk &&
        this.connectorStore.isAutodeskConnected
      )
    },
  },
  watch: {
    selectedAconexProject(project) {
      this.getAconexDocumentSchema(project.id)
    },
    selectedProviderItem(provider) {
      this.connectorStore.isProcoreAlbumFetchFailed = false
      this.selectedProcoreProject = null
      this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoSelectProvider, {
        provider,
      })
    },
    "currentProgressPhoto.cameraExids"() {
      this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoSelectCameras)
    },
    "currentProgressPhoto.notifyDays"(days) {
      this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoSelectDays, {
        days,
      })
    },
    "currentProgressPhoto.timezone"(timezone) {
      this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoSelectTimezone, {
        timezone,
      })
    },
    "currentProgressPhoto.config.recipients"() {
      this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoSelectMember)
    },
    progressPhotoTime(time) {
      this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoSelectTime, {
        time,
      })
    },
  },
  async created() {
    this.initForm()
  },
  methods: {
    async validateAconexForm() {
      return await this.$refs.aconexForm.$refs.observer.validate()
    },
    async getAconexDocumentSchema(projectId) {
      try {
        this.isGettingDocumentSchema = true
        this.aconexDocumentSchema = await EvercamApi.aconex.getDocumentSchema(
          projectId
        )
      } catch (error) {
        this.$notifications.error({
          text: this.$t("content.connectors.upload_to_procore_failed"),
          error,
        })
      } finally {
        this.isGettingDocumentSchema = false
        this.initAconexForm(
          delete this.currentProgressPhoto.config.projectId &&
            this.currentProgressPhoto.config
        )
      }
    },
    initForm() {
      this.days = weekDays
      if (this.progressPhotoStore.isEditDialog) {
        this.progressPhotoTime = this.currentProgressPhoto.notifyTime
        this.selectedProviderItem = this.currentProgressPhoto.provider
        this.openConnectorPromptDialog()
        this.getSelectedItem(this.currentProgressPhoto.config)
      }
    },
    initAconexForm(config = {}) {
      this.aconexForm = this.aconexDocumentSchema.reduce((acc, item) => {
        const key = camelize(item.identifier)
        const value =
          key && config[key] ? config[key] : this.getDefaultValue(item)

        return Object.assign(acc, { [item.identifier]: value })
      }, {})
    },
    getDefaultValue(field) {
      if (field.identifier === "HasFile") {
        return true
      }
      if (field.identifier === "Title") {
        return "Progress Photo"
      }
      if (field.identifier === "DocumentNumber") {
        return "Progress Photo"
      }
      if (field.identifier === "Author") {
        return "Evercam Ltd"
      }
      if (field.dataType === "BOOLEAN") {
        return false
      }
      if (field.dataType === "DATE") {
        return this.$moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
      }

      return ""
    },
    openConnectorPromptDialog() {
      const shouldOpenConnectorPromptDialog =
        (this.selectedProviderItem === AutomationProvider.Procore &&
          !this.connectorStore.isProcoreConnected) ||
        (this.selectedProviderItem === AutomationProvider.Aconex &&
          !this.connectorStore.isAconexConnected) ||
        (this.selectedProviderItem === AutomationProvider.Autodesk &&
          !this.connectorStore.isAutodeskConnected)
      if (shouldOpenConnectorPromptDialog) {
        this.connectorStore.openConnectorPromptDialog(this.selectedProviderItem)
      }
    },
    getProviderItem(item) {
      this.selectedProviderItem = item.value
      this.openConnectorPromptDialog()
    },
    async getSelectedItem(config) {
      if (this.selectedProviderItem === AutomationProvider.Email) {
        return
      }
      if (this.selectedProviderItem === AutomationProvider.Procore) {
        this.selectedProcoreProject =
          this.connectorStore.procoreUserProjects.find(
            (item) => item.id === config.projectId
          )

        await this.connectorStore.getProcoreProjectAlbums({
          companyId: config.companyId,
          projectId: config.projectId,
        })

        const filteredAlbum = this.connectorStore.procoreProjectAlbums.filter(
          (album) => album.id === config.categoryId
        )
        this.selectedProcoreProjectAlbum = filteredAlbum[0]

        return
      }
      if (this.selectedProviderItem === AutomationProvider.Aconex) {
        this.selectedAconexProject =
          this.connectorStore.aconexUserProjects.find(
            (item) => item.id == config.projectId
          )
      }
      if (this.selectedProviderItem === AutomationProvider.Autodesk) {
        this.selectedAutodeskHub = this.connectorStore.autodeskHubs.find(
          (item) => item.value == config.hubId
        )
        await this.handleHubChange(this.selectedAutodeskHub)
        this.selectedAutodeskProject =
          this.connectorStore.autodeskProjects.find(
            (item) => item.value == config.projectId
          )
        await this.handleProjectChange(this.selectedAutodeskProject)
        this.selectedAutodeskFolder = [
          await this.connectorStore.fetchAutodekFolder(
            this.selectedAutodeskProject?.value,
            config.folderId
          ),
        ]
      }
    },
    toggleAll() {
      if (this.currentProgressPhoto.notifyDays?.length !== 7) {
        this.currentProgressPhoto.notifyDays = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ]
      } else {
        this.currentProgressPhoto.notifyDays = []
        this.$nextTick(() => {
          this.$refs.progressPhotoDays.validate()
        })
      }
    },
    async openDeleteDialog() {
      if (
        await this.$confirmDialog.open({
          title: this.$t("content.progress_photo.delete"),
          message: this.$t("content.progress_photo.delete_confirmation"),
        })
      ) {
        this.removeProgressPhoto()
      }
    },
    removeProgressPhoto() {
      this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoDelete, {
        automationId: this.progressPhotoStore.progressPhotoId,
      })
      this.progressPhotoStore.destroyProgressPhoto(
        this.progressPhotoStore.progressPhotoId
      )
      this.progressPhotoStore.progressPhotoDialog = false
    },
    async addProgressPhoto() {
      if (this.selectedProviderItem === AutomationProvider.Aconex) {
        const isValid = await this.validateAconexForm()
        if (!isValid) {
          return
        }
      }
      this.loading = true
      this.currentProgressPhoto.notifyTime = this.progressPhotoTime
      if (this.progressPhotoStore.isEditDialog) {
        this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoEdit)
        await this.progressPhotoStore.updateProgressPhoto({
          data: this.getFormattedProgressPhoto(),
          id: this.progressPhotoStore.progressPhotoId,
        })
      } else {
        this.$analytics.saveEvent(AnalyticsEvent.ProgressPhotoCreate)
        await this.progressPhotoStore.createProgressPhoto(
          this.getFormattedProgressPhoto()
        )
      }

      this.loading = false
      this.progressPhotoStore.progressPhotoDialog = false
      await this.progressPhotoStore.getProgressPhotos()
    },
    getFormattedProgressPhoto() {
      const arrayToString = (array) => array?.join() || ""
      let config
      if (this.selectedProviderItem === AutomationProvider.Email) {
        config = {
          recipients: arrayToString(
            this.currentProgressPhoto.config.recipients.map(
              (value) => value.email
            )
          ),
        }
      } else if (this.selectedProviderItem === AutomationProvider.Procore) {
        config = {
          projectId: this.selectedProcoreProject?.id,
          companyId: this.selectedProcoreProject?.company?.id,
          categoryId: this.selectedProcoreProjectAlbum?.id,
        }
      } else if (this.selectedProviderItem === AutomationProvider.Aconex) {
        config = {
          ...this.aconexForm,
          projectId: this.selectedAconexProject?.id,
        }
      } else if (this.selectedProviderItem === AutomationProvider.Autodesk) {
        config = {
          hubId: this.selectedAutodeskHub?.value,
          projectId: this.selectedAutodeskProject?.value,
          folderId: this.selectedAutodeskFolder?.[0]?.value,
          accFileType: this.selectedAutodeskFolder?.[0]?.allowedFileType,
          accFolderType: this.selectedAutodeskFolder?.[0]?.allowedFolderType,
        }
      }

      return {
        cameraExids: arrayToString(this.currentProgressPhoto.cameraExids),
        subject: this.accountStore.email,
        provider: this.selectedProviderItem,
        config: config,
        notifyDays: arrayToString(this.currentProgressPhoto.notifyDays),
        notifyTime: this.currentProgressPhoto.notifyTime,
        timezone: this.currentProgressPhoto.timezone,
        isPaused: this.progressPhoto.isPaused,
      }
    },
    updateTime(time) {
      this.$refs.startTimeMenu.save(time)
    },
    filterCameras(cameras) {
      return cameras.filter(
        (camera) =>
          ![
            CameraStatus.Decommissioned,
            CameraStatus.WaitingForSiteVisit,
          ].includes(camera.status)
      )
    },
    async handleHubChange(hub) {
      if (!hub.value) {
        return
      }
      this.selectedAutodeskFolder = null
      this.selectedAutodeskProject = null
      await this.connectorStore.fetchAutodeskProjects(hub.value)
    },
    async handleProjectChange(project) {
      this.selectedAutodeskFolder = null
      await this.connectorStore.fetchAutodeskFolders(
        this.selectedAutodeskHub?.value,
        project.value
      )
    },
    async fetchAutodeskSubfolder(item) {
      await this.connectorStore.fetchAutodeskSubfolder(
        item,
        this.selectedAutodeskProject?.value
      )
    },
    async fetchProcoreProjectAlbums(item) {
      this.loading = true
      await this.connectorStore.getProcoreProjectAlbums(item)
      this.loading = false
    },
  },
}
